<template>
  <section class="section">
    <div class="container has-text-centered">
      <h1 class="title center">Penguin Random House CoffeeConnection</h1>
    </div>
    <div class="container has-text-centered">
      <div class="columns">
        <div class="column"></div>
        <div class="column is-half">
          <div class="block mt-4">
             <span v-html="this.texts.desc"></span>
          </div>

          <label for="" class="label has-text-centered mt-6"
            >Zur Anmeldung bitte hier deine E-Mail-Adresse eingeben</label
          >
          <div class="control has-icons-left mb-6">
            <input
              type="text"
              class="input is-medium has-text-centered"
              name="email"
              id="email"
              v-model="email"
              placeholder="vorname.nachname@penguinrandomhouse.de"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </div>

          <div class="notification is-danger" v-if="hasErrorMessage">
            {{ errorMessage }}
          </div>

          <div class="notification is-success" v-if="linkSend">
            Wir haben Dir eine E-Mail mit dem Login-Link geschickt.
          </div>

          <div v-if="!linkSend" class="block">
            <input type="checkbox" id="optin" name="optin" @click="toggleOptIn" />&nbsp;
            <label for="optin"><span v-html="this.texts.optin"></span></label> 
          </div>

          <button
            class="button is-primary is-medium"
            :disabled="!optInSelected"
            @click="login"
            v-if="!linkSend"
          >
            Login-Link anfordern
          </button>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import config from "../config";

export default {
  name: "ApiLogin",
  mounted: function () {
    this.isLoading = true;
    this.loadData();
  },
  data: function () {
    return {
      email: null,
      optInSelected: false,
      errorMessage: "",
      linkSend: false,
      isLoading: true,
      texts: {
        desc: "Description",
        optin: "Opt-in",
      },
    };
  },
  computed: {
    hasErrorMessage: function () {
      return this.errorMessage != "";
    },
  },
  methods: {
    async loadData() {
      let { data } = await axios.get(config.serviceUrl + "/api/Texts");
      if (data) {
        this.isLoading = false;
        let desc = data.filter((text) => text.name == "LoginDesc");
        if (desc.length > 0) {
          this.texts.desc = desc[0].body;
        }
        let optin = data.filter((text) => text.name == "LoginOptIn");
        if (optin.length > 0) {
          this.texts.optin = optin[0].body;
        }
      }
    },
    login: async function () {
      this.errorMessage = "";
      let { data } = await axios.get(
        config.serviceUrl + "/api/SignIn?email=" + this.email
      );
      if (data.message) {
        this.errorMessage = data.message;
      } else {
        this.linkSend = true;
      }
    },
    toggleOptIn: function () {
      this.optInSelected = !this.optInSelected;
    },
  },
};
</script>