import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Settings from './views/Settings.vue'
import Imprint from './views/Imprint.vue'
import DataPrivacy from './views/DataPrivacy.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/login',
      name: 'login2',
      component: Login
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint
    },
    {
      path: '/dataprivacy',
      name: 'dataprivacy',
      component: DataPrivacy
    },
    {
      path: '/settings/:sessionId',
      name: 'settings',
      component: Settings
    }
  ]
})
