<template>
  <section>
    <section class="section" v-if="loaded && !isSaved">
      <div class="container has-text-centered">
        <h1 class="title center mb-6">
          Herzlich Willkommen, {{ firstname }} {{ lastname }}
        </h1>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <div class="block mt-4 has-text-justified">
              <span v-html="this.texts.desc"></span>
            </div>
          </div>
          <div class="column"></div>
        </div>

        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <div class="field">
              <label for="" class="label">Vorname</label>
              <input
                type="text"
                class="input"
                name="firstname"
                id="firstname"
                v-model="firstname"
              />
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label for="" class="label">Nachname</label>
              <input
                type="text"
                class="input"
                name="firstname"
                id="lastname"
                v-model="lastname"
              />
            </div>
          </div>
          <div class="column"></div>
        </div>

        <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <fieldset class="disabled" disabled>
              <div class="field">
                <label for="" class="label">E-Mail-Adresse</label>
                <input
                  type="text"
                  class="input"
                  disabled
                  name="email"
                  id="email"
                  v-model="email"
                />
              </div>
            </fieldset>
          </div>
          <div class="column"></div>
        </div>

      <!--   <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <label for="" class="label">Firma</label>
            <div class="select is-fullwidth">
              <select
                class="input"
                name="company"
                id="company"
                v-model="companyId"
              >
                <option
                  v-for="company in companies"
                  :key="company.id"
                  :value="company.id"
                >
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column"></div>
        </div> -->

        <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <label for="" class="label">Bevorzugte Sprache</label>
            <div class="select is-fullwidth">
              <select
                class="input"
                name="language"
                id="language"
                v-model="preferredLanguage"
              >
                <option
                  v-for="language in languages"
                  :key="language.code"
                  :value="language.code"
                >
                  {{ language.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column"></div>
        </div>

        <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <label for="" class="label">Termine</label>
            <div class="tags">
              <span
                :class="{
                  tag: true,
                  'is-size-6': true,
                  'is-info': date.isSelected,
                }"
                v-for="date in dates"
                :key="date.id"
                @click="selectDate(date)"
                >{{ formatDate(date.date) }}</span
              >
            </div>
          </div>
          <div class="column"></div>
        </div>

        <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <label for="" class="label">Berufliche Themenfelder</label>
            <div class="mb-4">
              <a @click="selectAllTags()" class="is-size-6">Alle auswählen</a>
              &bull;
              <a @click="deselectAllTags()" class="is-size-6">Alle abwählen</a>
            </div>
            <div class="tags">
              <span
                :class="{
                  tag: true,
                  'is-size-6': true,
                  'is-info': tag.isSelected,
                }"
                v-for="tag in tags"
                :key="tag.id"
                @click="selectTag(tag)"
                >{{ tag.name }}</span
              >
            </div>
          </div>
          <div class="column"></div>
        </div>

        <div class="columns">
          <div class="column"></div>
          <div class="column is-half has-text-centered">
            <button class="button" @click="save" :disabled="!isValid">
              Speichern & Absenden
            </button>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </section>
    <section v-if="isSaved">
      <div class="columns mb-6">
        <div class="column"></div>
        <div class="column is-half">
          <div class="has-text-centered">
              <h1 class="mb-3 is-size-4">Coffee Connection @PRHVG</h1>
            <br>
            Danke, Deine Daten wurden erfolgreich versendet. 
            <br><br>
            Viel Spaß beim connecten!
          </div>
        </div>
        <div class="column"></div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import config from "../config";

export default {
  name: "ApiSettings",
  data: function () {
    return {
      userid: "",
      firstname: "",
      lastname: "",
      email: "",
      nextRequestId: "",
      companyId: "",
      companies: [],
      preferredLanguage: "",
      languages: [],
      selectedDates: [],
      dates: [],
      selectedTags: [],
      tags: [],
      loaded: false,
      isSaved: false,
      texts: {
        desc: "Description",
      },
    };
  },
  mounted: function () {
    this.loadData();
  },
  computed: {
    isValid: function () {
      return (
        this.firstname != "" &&
        this.firstname != null &&
        this.lastname != "" &&
        this.lastname != null &&
        this.tagsSelected
      );
    },
    tagsSelected() {
      return this.tags.filter((tag) => tag.isSelected == true).length > 0;
    },
  },
  methods: {
    loadData: async function () {
      try {
        let { data } = await axios.get(config.serviceUrl + "/api/Texts");
        if (data) {
          this.isLoading = false;
          let desc = data.filter((text) => text.name == "SettingsDesc");
          if (desc.length > 0) {
            this.texts.desc = desc[0].body;
          }
        }

        ({ data } = await axios.get(
          config.serviceUrl + "/api/Users/" + this.$route.params.sessionId
        ));
        if (data) {
          this.userid = data.id;
          this.firstname = data.firstname;
          this.lastname = data.lastname;
          this.email = data.eMailAddress;
          this.companyId = data.companyId;
          this.preferredLanguage = data.preferredLanguage;
          this.selectedDates = data.dates;
          this.selectedTags = data.tags;

          ({ data } = await axios.get(config.serviceUrl + "/api/Companies"));
          this.companies = data;

          ({ data } = await axios.get(config.serviceUrl + "/api/Languages"));
          this.languages = data;

          ({ data } = await axios.get(config.serviceUrl + "/api/Tags"));
          this.tags = data;

          ({ data } = await axios.get(config.serviceUrl + "/api/Dates"));
          this.dates = data;

          for (let i = 0; i < this.dates.length; i++) {
            if (this.selectedDates.indexOf(this.dates[i].id) >= 0) {
              this.dates[i].isSelected = true;
            }
          }

          for (let i = 0; i < this.tags.length; i++) {
            if (this.selectedTags.indexOf(this.tags[i].id) >= 0) {
              this.tags[i].isSelected = true;
            }
          }

          this.loaded = true;
        }
      } catch (e) {
        console.log(e);
        this.$router.push({ path: "/login" });
      }
    },
    formatDate: function (date) {
      let formattedDate = new Date(date).toLocaleString("de-DE");
      return (
        formattedDate
          .substring(0, formattedDate.lastIndexOf(":"))
          .replace(",", " -") + " Uhr"
      );
    },
    selectDate: function (selectedDate) {
      const date = this.dates.filter((d) => d.id == selectedDate.id)[0];
      if (date) {
        date.isSelected = !date.isSelected;
      }
    },
    selectTag: function (selectedTag) {
      const tag = this.tags.filter((t) => t.id == selectedTag.id)[0];
      if (tag) {
        tag.isSelected = !tag.isSelected;
      }
    },
    selectAllTags() {
      this.tags.map((tag) => (tag.isSelected = true));
    },
    deselectAllTags() {
      this.tags.map((tag) => (tag.isSelected = false));
    },
    save: async function () {
      let dates = this.dates.filter((d) => d.isSelected).map((d) => d.id);
      let tags = this.tags.filter((t) => t.isSelected).map((t) => t.id);
      let payload = {
        id: this.userid,
        firstname: this.firstname,
        lastname: this.lastname,
        companyId: this.companyId,
        preferredLanguage: this.preferredLanguage,
        tags: tags,
        dates: dates,
      };
      let res = await axios.put(
        config.serviceUrl + "/api/Users/" + this.userid,
        payload
      );
      if (res.status == 200) {
        this.isSaved = true;
      }
    },
  },
};
</script>

<style scoped>
.tag {
  cursor: pointer;
}
</style>