<template>
  <section class="section">
    <div class="container has-text-centered">
      <div class="columns">
        <div class="column"></div>
        <div class="column is-half">
          <h1 class="title">Impressum</h1>

          <div class="block">
             <span v-html="this.texts.imprint"></span>
          </div>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </section>
</template>



<script>
import axios from "axios";
import config from "../config";

export default {
  name: "ApiImprint",
  mounted: function () {
    this.loadData();
  },
  data: function () {
    return {
      texts: {
        imprint: "Impressum",
      },
    };
  },
  methods: {
    async loadData() {
      let { data } = await axios.get(config.serviceUrl + "/api/Texts");
      if (data) {
        let imprint = data.filter((text) => text.name == "Imprint");
        if (imprint.length > 0) {
          this.texts.imprint = imprint[0].body;
        }
      }
    },
    
    
  },
};
</script>


<style scoped>
h3 {
  font-weight: bold;
}

.block {
  text-align: left;
}
</style>